import {
  WppActionButton,
  WppCard,
  WppIconGear,
  WppIconMore,
  WppMenuContext,
  WppListItem,
  WppTypography,
  WppDivider,
  WppIconApp,
  WppIconPlus,
  WppIconLink,
  WppIconFile,
  WppIconTrash,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeleteActivityApi } from 'api/canvas/mutation/useDeleteActivityApi'
import { showDeleteModal } from 'components/common/deleteModal/DeleteModal'
import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { ACTION_ANALYTICS, CanvasItemType } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { useAssignMember } from 'hooks/useAssignMember'
import { useProject } from 'hooks/useProject'
import { useTemplate } from 'hooks/useTemplate'
import { useToast } from 'hooks/useToast'
import { showAddLinksModal } from 'pages/project/components/canvas/components/addLinksModal/AddLinksModal'
import { showAppPickerModal } from 'pages/project/components/canvas/components/appPikerModal/AppPickerModal'
import { AttachedFile } from 'pages/project/components/canvas/components/attachedFile/AttachedFile'
import { showAttachFilesModal } from 'pages/project/components/canvas/components/attachFilesModal/AttachFilesModal'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ExternalLinkView } from 'pages/project/components/canvas/components/externalLink/ExternalLinkView'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import { SelectDateInline } from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { SelectPersonInline } from 'pages/project/components/canvas/components/selectPersonInline/SelectPersonInline'
import { ShowMoreItems } from 'pages/project/components/canvas/components/showMoreItems/ShowMoreItems'
import styles from 'pages/project/components/canvas/fluidCanvas/components/fluidActivity/FluidEditableActivity.module.scss'
import { useUpdateAppMargin } from 'pages/project/components/canvas/fluidCanvas/components/fluidActivity/utils'
import { useUpdateItem } from 'pages/project/components/canvas/hooks/useUpdateItem'
import { showAddEditActivityModal } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AddEditActivityModal'
import commonStyles from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AppActivityItem.module.scss'
import { MAX_LINKS_COUNT, useUiPartEnabled } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { showConfirmExternalModal } from 'pages/project/components/members/components/confirmIExternalModal/ConfirmExternalModal'
import { TaskStatusChangeDropdown } from 'pages/project/components/tasks/components/changeStatus/TaskStatusChangeDropdown'
import { StatusText } from 'pages/project/components/tasks/components/statusText/StatusText'
import { useExternalStatusList } from 'pages/project/hooks/useExternalStatusList'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectMember } from 'types/projects/projectMembers'
import { ProcessType } from 'types/projects/projects'
import { ProjectPartKey } from 'types/projects/projectViewSettings'
import { TaskStatus } from 'types/projects/tasks'
import { ActivityItem, ExternalLink, PhaseItemType } from 'types/projects/workflow'
import { makeStringShorter } from 'utils/common'
import { isTaskStatusResolved } from 'utils/project'

interface Props {
  containerId: string
  activityItem: ActivityItem
  showAction: boolean
  isEditable: boolean
  isDisabled: boolean
  childrenCount: number
  variant?: 'primary' | 'secondary'
  sortedExternalLinks?: ExternalLink[]
  isIAssignToThisPhase?: boolean
}

export const FluidEditableActivity = ({
  containerId,
  activityItem,
  showAction,
  isEditable,
  isDisabled,
  variant,
  sortedExternalLinks,
  childrenCount,
}: Props) => {
  const { t } = useTranslation()
  const appsStarRef = useRef<HTMLDivElement>(null)
  const activityCardRef = useRef<HTMLWppCardElement>(null)
  const { name, startDate, endDate, id, task, files, description } = activityItem
  const isResolved = !!task && isTaskStatusResolved(task)

  const projectContext = useProject()
  const templateContext = useTemplate()
  const { me } = useHasProjectRole()
  const { trackAction } = useTrackAction()

  const isTemplate = !!templateContext

  const projectId = projectContext?.project.id || templateContext?.template.id
  const isInactive = projectContext?.isInactive

  const assignMember = useAssignMember(activityItem.assignUser)
  const isUIPartEnabled = useUiPartEnabled(isEditable)

  const { showToast } = useToast()
  const { mutateAsync: handleDeleteActivity } = useDeleteActivityApi()

  const { updateItem } = useUpdateItem({
    id: activityItem.id,
    type: PhaseItemType.Activity,
    name: activityItem.name,
    processType: ProcessType.FLUID,
    projectId: projectId,
    useExternalStatuses: projectContext?.useExternalStatuses,
  })

  const toggleAssignee = useCallback(
    async (newAssignee: ResponsibleUser) => {
      const isDeselecting = assignMember?.id === newAssignee.id

      if (isDeselecting || (!newAssignee.isExternal && newAssignee.isMember)) {
        await updateItem({
          assignUser: isDeselecting ? null : newAssignee,
          analyticsAction: ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_ASSIGN,
        })
      } else {
        showConfirmExternalModal({
          title: t(
            newAssignee.isExternal
              ? 'modals.invite_member.assign_external_title'
              : 'modals.invite_member.assign_member_title',
          ),
          description: t(
            newAssignee.isExternal
              ? newAssignee.isMember
                ? 'modals.invite_member.assign_external_description_member'
                : 'modals.invite_member.assign_external_description'
              : 'modals.invite_member.assign_member_description',
            {
              member: `${newAssignee.firstname} ${newAssignee.lastname}`,
              item: activityItem.name,
            },
          ),
          withDelete: false,
          members: [newAssignee as unknown as ProjectMember],
          isMember: newAssignee.isMember,
          inviteMembers: async ids => {
            if (!!ids.length) {
              await updateItem({
                assignUser: isDeselecting ? null : newAssignee,
                analyticsAction: ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_ASSIGN,
              })
            }
          },
        })
      }
    },
    [activityItem.name, assignMember?.id, t, updateItem],
  )

  const handleChangeDates = async (dates: Date[]) => {
    await updateItem({ dates, analyticsAction: ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_DATES_EDIT })
  }

  const editActivityModal = () => {
    showAddEditActivityModal({
      projectId: projectId,
      phaseId: 'fluid',
      activity: activityItem,
      isDisabled: isInactive,
      isWrikeConnected: !!projectContext?.project.wrike?.isConnected,
      isTemplate,
    })
  }

  const handleDelete = async () => {
    try {
      await handleDeleteActivity({ id })
      showToast({
        type: 'success',
        message: t('project.canvas.toast.remove_activity', { query: makeStringShorter(activityItem.name) }),
      })

      if (projectContext) {
        const { project } = projectContext
        const analyticsData = {
          ...getCommonProjectAnalyticsData(project, me),
          type: CanvasItemType.ACTIVITY,
        }

        trackAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_DELETE, analyticsData)
      }

      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_FLUID])

      if (templateContext?.isSingleFluidActivityItem && templateContext?.template.isPublished) {
        await queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES_BY_ID, { id: projectId }])
      }
    } catch (e) {
      showToast({ type: 'error', message: t('project.canvas.toast.failed_operation_remove', { query: 'activity' }) })
      console.error(e)
    }
  }

  const childPlaceholders = useMemo(() => {
    const list: null[] = []
    list.length = childrenCount
    list.fill(null)
    return list
  }, [childrenCount])

  const externalStatuses = useExternalStatusList()

  useUpdateAppMargin(containerId, activityCardRef, appsStarRef)

  const combinedFilesAndLinks = useMemo(
    () => [...(files ?? []), ...(sortedExternalLinks ?? [])],
    [files, sortedExternalLinks],
  )

  const handleChangeStatus = useCallback(
    async (status: string) => {
      await updateItem({ status, analyticsAction: ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_STATUS_CHANGE })
    },
    [updateItem],
  )

  return (
    <>
      <WppCard
        variant={variant}
        data-testid={`activity-card-${activityItem.id}`}
        size="s"
        ref={activityCardRef}
        className={clsx(commonStyles.itemContainer, styles.fluidItemContainer, {
          [commonStyles.disabledItem]: isDisabled || isInactive,
        })}
      >
        <Flex direction="column" className={styles.nameWrapper} gap={8}>
          <Flex align="center" justify="between">
            <Truncate lines={2} type="m-strong" title={name} data-testid="phase-item-name">
              {name}
            </Truncate>
            <Flex align="center" className={styles.alignSelf}>
              {showAction && !isInactive && (
                <WppMenuContext dropdownConfig={{ appendTo: () => document.body }}>
                  <WppActionButton slot="trigger-element" variant="secondary">
                    <WppIconMore slot="icon-start" direction="horizontal" />
                  </WppActionButton>

                  <Flex direction="column">
                    <WppListItem onWppChangeListItem={editActivityModal} data-testid="context-settings">
                      <WppIconGear slot="left" />
                      <WppTypography slot="label" type="s-body">
                        {t('project.canvas.settings')}
                      </WppTypography>
                    </WppListItem>
                    <div style={{ display: isInactive ? 'none' : 'block' }}>
                      <WppListItem
                        onWppChangeListItem={() =>
                          showDeleteModal({
                            title: t('modals.remove_activity.delete_item'),
                            subTitle: t(
                              templateContext?.isSingleFluidActivityItem && templateContext?.template.isPublished
                                ? 'template.delete_last_activity_template'
                                : 'modals.remove_activity.delete_confirm',
                            )!,
                            deleteText: t('common.btn_delete')!,
                            onDelete: handleDelete,
                          })
                        }
                        data-testid="context-remove"
                      >
                        <WppIconTrash slot="left" />
                        <WppTypography slot="label" type="s-body">
                          {t('common.btn_delete')}
                        </WppTypography>
                      </WppListItem>
                    </div>
                  </Flex>

                  {/* DO_NOT_DELETE: ternary operator coz error: Can't remove ReactNode. More like Tippy issue, remove node firstly */}
                  <div style={{ display: isInactive ? 'none' : 'block' }}>
                    <WppDivider className={styles.actionsGap} />
                    <TaskStatusChangeDropdown
                      onChange={status => handleChangeStatus(status)}
                      selectedStatus={
                        projectContext?.useExternalStatuses ? task?.wrike?.externalStatusId! : task?.status
                      }
                      externalStatuses={externalStatuses}
                      useExternalStatuses={projectContext?.useExternalStatuses}
                      showConfirm={false}
                    />
                  </div>
                </WppMenuContext>
              )}
            </Flex>
          </Flex>
          {description && (
            <Truncate
              lines={3}
              title={description}
              type="xs-body"
              data-testid="description"
              className={styles.greyColor800}
            >
              {description}
            </Truncate>
          )}

          <Flex direction="column" gap={8}>
            {(task?.status === TaskStatus.COMPLETED || task?.status === TaskStatus.ARCHIVED) && (
              <StatusText
                isExternal={projectContext?.useExternalStatuses}
                statusKey={projectContext?.useExternalStatuses ? task?.wrike?.externalStatus! : task?.status}
              />
            )}
            {!isInactive && showAction ? (
              <Flex align="center" gap={4}>
                {!isTemplate && (
                  <>
                    <SelectPersonInline
                      selectedId={assignMember?.id}
                      onChange={toggleAssignee}
                      projectId={projectId}
                      isWrikeConnected={!!projectContext?.project.wrike?.isConnected}
                    >
                      <ResponsiblePerson assignMember={assignMember} size="xs" />
                    </SelectPersonInline>

                    <SelectDateInline
                      startDate={startDate}
                      endDate={endDate}
                      resolved={isResolved}
                      onChange={dates => handleChangeDates(dates)}
                    />
                  </>
                )}

                <div className={commonStyles.addMenuWrapper}>
                  <WppMenuContext data-testid="context-add">
                    <WppActionButton slot="trigger-element">
                      <WppIconPlus slot="icon-start" />
                      {t('project.canvas.btn_add_item')}
                    </WppActionButton>
                    <Flex direction="column" gap={4}>
                      <WppListItem
                        onWppChangeListItem={() =>
                          showAppPickerModal({
                            projectId,
                            selectedCanvas: ProcessType.FLUID,
                            activityId: id,
                            isTemplate,
                          })
                        }
                        data-testid="context-add-app"
                      >
                        <WppIconApp slot="left" />
                        <p slot="label">{t('project.canvas.application')}</p>
                      </WppListItem>
                      <WppListItem
                        onWppChangeListItem={() => showAttachFilesModal({ projectId, activityId: activityItem.id })}
                      >
                        <WppIconFile slot="left" />
                        <p slot="label">{t('project.canvas.files')}</p>
                      </WppListItem>
                      <WppListItem
                        disabled={(sortedExternalLinks?.length ?? 0) >= MAX_LINKS_COUNT}
                        onWppChangeListItem={() =>
                          showAddLinksModal({
                            projectId,
                            activityId: activityItem.id,
                            existingCount: sortedExternalLinks?.length ?? 0,
                          })
                        }
                      >
                        <WppIconLink slot="left" />
                        <p slot="label">{t('project.canvas.link')}</p>
                      </WppListItem>
                    </Flex>
                  </WppMenuContext>
                </div>
              </Flex>
            ) : (
              !isTemplate && (
                <Flex gap={10} align="center">
                  {isUIPartEnabled(ProjectPartKey.ResponsiblePersons) && (
                    <ResponsiblePerson assignMember={assignMember} data-testid="phase-item-assignee" size="xs" />
                  )}
                  {isUIPartEnabled(ProjectPartKey.Dates) && (
                    <Calendar
                      startDate={startDate}
                      endDate={endDate}
                      data-testid="phase-item-dates"
                      resolved={isResolved}
                    />
                  )}
                </Flex>
              )
            )}
          </Flex>
        </Flex>
        <WppDivider className={commonStyles.divider} />

        {!!combinedFilesAndLinks.length && (
          <Flex direction="column" gap={4} className={styles.cardWrapper} data-testid="activity-files-and-links">
            <ShowMoreItems maxToShow={3}>
              {combinedFilesAndLinks.map(item => (
                <div key={item.id}>
                  {'fileType' in item && item.fileType && (
                    <AttachedFile
                      file={item}
                      key={item.id}
                      activityId={activityItem.id}
                      isDisabled={isDisabled}
                      showAction={showAction}
                      isInFluid
                      variant="secondary"
                      isInactive={isInactive}
                    />
                  )}

                  {'url' in item && item.url && (
                    <ExternalLinkView
                      activityId={activityItem.id}
                      link={item}
                      isDisabled={isDisabled}
                      key={item.id}
                      variant="secondary"
                      isInactive={isInactive}
                      linkActions={showAction}
                    />
                  )}
                </div>
              ))}
            </ShowMoreItems>
          </Flex>
        )}

        <div ref={appsStarRef} />

        {/* extending activity in such manner - to fit it size for all children AppNodes */}
        {childPlaceholders.map((_, idx) => (
          <div key={idx} id="" className={styles.innerAppPlaceholder} />
        ))}
      </WppCard>
    </>
  )
}
