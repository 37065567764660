import { SelectChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppSelectCustomEvent } from '@platform-ui-kit/components-library/loader'
import { WppListItem, WppSelect } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { useField } from 'hooks/form/useField'
import { useProvideFieldFocus } from 'hooks/form/useProvideFieldFocus'

export interface FormSelectOption {
  label: string
  value: string
}

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppSelect>, 'onChange' | 'value' | 'onWppBlur'> {
  name: string
  options: FormSelectOption[]
  emptyValue?: any
  toggleSelection?: boolean
  showValidationMessage?: boolean
  boundaryElement?: MayBeNull<HTMLElement>
}

export const FormSelect = forwardRef<HTMLWppSelectElement, Props>(
  (
    {
      name,
      options,
      message,
      dropdownPosition = 'fixed',
      messageType,
      emptyValue = '',
      toggleSelection,
      boundaryElement,
      onWppChange,
      showValidationMessage = true,
      ...rest
    },
    ref,
  ) => {
    const {
      field: { ref: fieldRef, value, onChange, onBlur },
      fieldState: { isTouched, error },
    } = useField({
      name,
    })

    const innerRef = useRef<HTMLWppSelectElement>(null)

    useProvideFieldFocus({
      fieldRef,
      setFocus: () => innerRef.current?.setFocus(),
    })

    const errorText = error?.message
    const shouldShowError = isTouched && !!errorText

    return (
      <WppSelect
        {...rest}
        ref={mergeRefs([innerRef, ref])}
        name={name}
        value={value}
        dropdownConfig={{
          onHidden: onBlur,
          popperOptions: {
            modifiers: [
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['top', 'bottom'],
                  boundary: boundaryElement ? boundaryElement : 'clippingParents',
                },
              },
            ],
          },
        }}
        dropdownPosition={dropdownPosition}
        messageType={shouldShowError ? 'error' : messageType}
        message={showValidationMessage && shouldShowError ? errorText : message}
        onWppChange={e => {
          onChange(e.detail.value)
          onWppChange?.(e)
        }}
      >
        {options.map(item => (
          <WppListItem
            key={item.value}
            value={item.value}
            onWppChangeListItem={e => {
              // `onWppChange` handler in `WppSelect` will not handle click on the selected item
              const isDeselecting = value === e.detail.value
              if (toggleSelection && isDeselecting) {
                onChange(emptyValue)

                const event = new CustomEvent('onDeselect', {
                  detail: { value: emptyValue },
                }) as WppSelectCustomEvent<SelectChangeEventDetail>
                Object.defineProperty(event, 'target', { writable: false, value: innerRef.current! })
                onWppChange?.(event)
              }
            }}
          >
            <span slot="label">{item.label}</span>
          </WppListItem>
        ))}
      </WppSelect>
    )
  },
)
