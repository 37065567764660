import { WppActionButton, WppCard, WppIconInfo, WppTooltip } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useAppLaunchErrors } from 'hooks/application/useAppErrors'
import { useProject } from 'hooks/useProject'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import styles from 'pages/project/components/canvas/fluidCanvas/components/fluidApplication/FluidApplication.module.scss'
import { useUiPartEnabled } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { StatusText } from 'pages/project/components/tasks/components/statusText/StatusText'
import { DetailsModalType } from 'types/common/utils'
import { Members } from 'types/members/members'
import { ProjectPartKey } from 'types/projects/projectViewSettings'
import { TaskStatus } from 'types/projects/tasks'
import { ApplicationItem } from 'types/projects/workflow'
import { isTaskStatusResolved } from 'utils/project'

interface Props {
  application: ApplicationItem
  templateView?: boolean
  handleOpenApp?: () => void
  assignMember?: Members
  activityId?: string
}

export const FluidPreviewApplication = memo(
  ({ application, templateView, handleOpenApp, assignMember, activityId }: Props) => {
    const { useExternalStatuses } = useProject() ?? {}
    const { t } = useTranslation()
    const [hoveredState, setHoveredState] = useState(false)
    const [, setSearchParams] = useSearchParams()
    const { name, startDate, endDate, externalAppName, task } = application
    const isResolved = !!task && isTaskStatusResolved(task)
    const isUIPartEnabled = useUiPartEnabled(false)

    const appLaunchErrors = useAppLaunchErrors(application)
    const showWarning = !!appLaunchErrors.length

    const openAppModal = useCallback(
      (id: string) =>
        activityId
          ? setSearchParams({
              view: DetailsModalType.ACTIVITY_DETAILS_PREVIEW,
              activityId,
              appId: application.id,
            })
          : setSearchParams({
              view: DetailsModalType.APP_DETAILS_PREVIEW,
              appId: id,
              ...(activityId && { activityId }),
            }),
      [activityId, setSearchParams, application.id],
    )

    if (application.hidden) return null

    return (
      <WppTooltip
        text={t('product.cant_launch_info.text')!}
        config={{ trigger: showWarning ? 'mouseenter' : 'manual', hideOnClick: false }}
        className={styles.tooltip}
      >
        <WppCard
          variant={!!activityId ? 'secondary' : 'primary'}
          size="s"
          className={clsx(styles.itemContainer, {
            [styles.innerItem]: !!activityId && !templateView,
            [styles.runApp]: !showWarning,
          })}
          data-testid={`phase-item-card-${application.id}`}
          onMouseEnter={() => setHoveredState(true)}
          onMouseLeave={() => setHoveredState(false)}
          onClick={
            !showWarning
              ? () => {
                  handleOpenApp?.()
                }
              : void 0
          }
        >
          <Flex direction="row" gap={12} align="center" data-testid={`application-item-preview-${application.id}`}>
            {isUIPartEnabled(ProjectPartKey.AppIcon) && (
              <Flex inline direction="column" className={styles.logo} justify="center" align="center">
                <ApplicationLogo logo={application?.logoUrl} size={40} />
              </Flex>
            )}
            <Flex direction="column">
              <Truncate
                title={name || externalAppName}
                lines={1}
                slot="header"
                type="m-strong"
                data-testid="phase-item-name"
              >
                {name || externalAppName}
              </Truncate>

              {isUIPartEnabled(ProjectPartKey.StatusBar) && task?.status === TaskStatus.COMPLETED && (
                <StatusText
                  isExternal={useExternalStatuses}
                  statusKey={useExternalStatuses ? task?.wrike?.externalStatus! : task?.status}
                  small
                />
              )}
              {isUIPartEnabled(ProjectPartKey.Dates) && startDate && endDate && (
                <>
                  {!(isUIPartEnabled(ProjectPartKey.StatusBar) && task?.status === TaskStatus.COMPLETED) && (
                    <Calendar
                      startDate={startDate}
                      endDate={endDate}
                      size="xs-body"
                      hideIcon
                      data-testid="canvas-application-dates"
                      resolved={isResolved}
                    />
                  )}
                </>
              )}
            </Flex>
            {/* @TODO: Need to fix this, create new component or change the way how we show this  */}
            <Flex justify="end" className={styles.responsiblePerson} gap={12} align="center">
              {hoveredState && !templateView ? (
                <WppActionButton
                  slot="trigger-element"
                  variant="secondary"
                  onClick={e => {
                    e.stopPropagation()
                    openAppModal(application.id)
                  }}
                >
                  <WppIconInfo slot="icon-start" />
                </WppActionButton>
              ) : (
                <>
                  {isUIPartEnabled(ProjectPartKey.ResponsiblePersons) && assignMember && (
                    <ResponsiblePerson
                      assignMember={assignMember}
                      size="xs"
                      hideIcon
                      data-testid="phase-item-assignee"
                    />
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </WppCard>
      </WppTooltip>
    )
  },
)
