import {
  WppActionButton,
  WppCard,
  WppIconGear,
  WppIconMore,
  WppMenuContext,
  WppListItem,
  WppTypography,
  WppDivider,
  WppIconApp,
  WppIconPlus,
  WppIconFile,
  WppIconLink,
  WppIconTrash,
} from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { PropsWithChildren, useContext, useMemo, useState } from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'

import { useDeleteActivityApi } from 'api/canvas/mutation/useDeleteActivityApi'
import { showDeleteModal } from 'components/common/deleteModal/DeleteModal'
import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { ACTION_ANALYTICS, CanvasItemType } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { useAssignMember } from 'hooks/useAssignMember'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { useTemplate } from 'hooks/useTemplate'
import { useToast } from 'hooks/useToast'
import { showAddLinksModal } from 'pages/project/components/canvas/components/addLinksModal/AddLinksModal'
import { showAppPickerModal } from 'pages/project/components/canvas/components/appPikerModal/AppPickerModal'
import { AttachedFile } from 'pages/project/components/canvas/components/attachedFile/AttachedFile'
import { showAttachFilesModal } from 'pages/project/components/canvas/components/attachFilesModal/AttachFilesModal'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ExternalLinkView } from 'pages/project/components/canvas/components/externalLink/ExternalLinkView'
import { Placeholder } from 'pages/project/components/canvas/components/placeholder/Placeholder'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import { SelectDateInline } from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { SelectPersonInline } from 'pages/project/components/canvas/components/selectPersonInline/SelectPersonInline'
import { ShowMoreItems } from 'pages/project/components/canvas/components/showMoreItems/ShowMoreItems'
import styles from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AppActivityItem.module.scss'
import { DragActivityItem } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/DragActivityItem'
import { MAX_LINKS_COUNT, useUiPartEnabled } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { DragContainerType, DnDItem } from 'pages/project/components/canvas/utils'
import { TaskStatusChangeDropdown } from 'pages/project/components/tasks/components/changeStatus/TaskStatusChangeDropdown'
import { StatusText } from 'pages/project/components/tasks/components/statusText/StatusText'
import { useExternalStatusList } from 'pages/project/hooks/useExternalStatusList'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { LinearDispatchContext } from 'providers/common/LinearGenericProvider'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectRole, AppPermissions } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'
import { ProjectPartKey } from 'types/projects/projectViewSettings'
import { TaskStatus } from 'types/projects/tasks'
import { ActivityItem, ExternalLink, PhaseItemType } from 'types/projects/workflow'
import { isEqualEmails, makeStringShorter } from 'utils/common'
import { isTaskStatusResolved } from 'utils/project'

type Props = PropsWithChildren<{
  activityItem: ActivityItem
  showAction: boolean
  isEditable: boolean
  isDisabled: boolean
  isInactive: boolean
  index: number
  changeAssignee: (newAssignee: ResponsibleUser) => Promise<void>
  changeDates: (dates: Date[]) => void
  changeStatus: (status: string) => void
  editActivity?: () => void
  deleteActivity?: () => void
  changeHidden?: (hidden: boolean) => void
  phaseItemId: string
  variant?: 'primary' | 'secondary'
  phaseId: string
  preview?: boolean
  sortedExternalLinks?: ExternalLink[]
  isDraggingDisabled?: boolean
  isIAssignToThisPhase?: boolean
  projectId: string
  processType: ProcessType
  isWrikeConnected?: boolean
  isTemplate?: boolean
}>

export const LinearEditableActivity = ({
  activityItem,
  showAction,
  isEditable,
  isDisabled: isDisabledProp,
  index,
  variant,
  preview,
  phaseId,
  phaseItemId,
  sortedExternalLinks,
  isIAssignToThisPhase,
  isDraggingDisabled,
  children,
  projectId,
  processType,
  isWrikeConnected,
  isTemplate,
  ...rest
}: Props) => {
  const { t } = useTranslation()
  const { changeDates, editActivity, changeAssignee, changeStatus } = rest
  const { name, startDate, endDate, id, task, files, description } = activityItem
  const isResolved = !!task && isTaskStatusResolved(task)

  const projectContext = useProject()
  const templateContext = useTemplate()
  const externalStatuses = useExternalStatusList()

  const { dropOnActivity } = useContext(LinearDispatchContext)
  const { trackAction } = useTrackAction()
  const [placeholderHeight, setPlaceholderHeight] = useState<MayBeNull<number>>(null)
  const isInactive = projectContext?.isInactive
  const isDisabled = Boolean(isDisabledProp || isInactive)

  const assignMember = useAssignMember(activityItem.assignUser, projectId)
  const isUIPartEnabled = useUiPartEnabled(isEditable)

  const {
    osContext: { userDetails },
  } = useOs()

  const { isPermitted } = useIsPermitted()
  const { hasRole, me } = useHasProjectRole()
  const isOwner =
    hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE) || templateContext?.isOwner
  const isIAssignToThisActivity = isEqualEmails(activityItem?.assignUser, userDetails.email)

  const dropDisable =
    isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE) || isIAssignToThisActivity || isIAssignToThisPhase
      ? false
      : !isOwner

  const { showToast } = useToast()
  const { mutateAsync: handleDeleteActivity } = useDeleteActivityApi()

  const handleDelete = async () => {
    try {
      await handleDeleteActivity({ id })
      showToast({
        type: 'success',
        message: t('project.canvas.toast.remove_activity', { query: makeStringShorter(activityItem.name) }),
      })

      if (projectContext) {
        const { project } = projectContext
        const analyticsData = {
          ...getCommonProjectAnalyticsData(project, me),
          type: CanvasItemType.ACTIVITY,
        }

        trackAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_DELETE, analyticsData)
      }

      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR])
    } catch (e) {
      showToast({ type: 'error', message: t('project.canvas.toast.failed_operation_remove', { query: 'activity' }) })
      console.error(e)
    }
  }

  const [{ isOverCurrent }, drop] = useDrop(
    () => ({
      accept: DragContainerType.Item,
      canDrop(item: DnDItem) {
        if (dropDisable) {
          return false
        }

        return item.type === PhaseItemType.Application
      },
      hover(dndItem: DnDItem) {
        setPlaceholderHeight(dndItem.height ?? null)
      },
      drop(dndItem: DnDItem, monitor) {
        const didDrop = monitor.didDrop()
        if (didDrop) return
        dropOnActivity(phaseItemId, dndItem)
      },
      collect: monitor => ({
        isOverCurrent: monitor.isOver({ shallow: true }) && monitor.canDrop(),
      }),
    }),
    [dropOnActivity, phaseItemId],
  )

  const combinedFilesAndLinks = useMemo(
    () => [...(files ?? []), ...(sortedExternalLinks ?? [])],
    [files, sortedExternalLinks],
  )

  return (
    <>
      <WppCard
        variant={variant}
        data-testid="activity-card"
        size="s"
        className={clsx(styles.itemContainer, {
          [styles.dragItem]: !isDisabled && !projectContext?.isInactive && !isDraggingDisabled,
        })}
      >
        <Flex direction="column" className={styles.nameWrapper} gap={8}>
          <Flex align="center" justify="between">
            <Truncate
              lines={2}
              type="m-strong"
              title={name}
              data-testid="phase-item-name"
              className={clsx({ [styles.disabledItem]: isDisabled })}
            >
              {name}
            </Truncate>

            <Flex align="center" className={styles.alignSelf}>
              {showAction && !projectContext?.isInactive && (
                <WppMenuContext key={index} dropdownConfig={{ appendTo: () => document.body }}>
                  <WppActionButton slot="trigger-element" variant="secondary">
                    <WppIconMore slot="icon-start" direction="horizontal" />
                  </WppActionButton>

                  <Flex direction="column">
                    <WppListItem onWppChangeListItem={editActivity} data-testid="context-settings">
                      <WppIconGear slot="left" />
                      <WppTypography slot="label" type="s-body">
                        {t('project.canvas.settings')}
                      </WppTypography>
                    </WppListItem>
                    <div style={{ display: isInactive ? 'none' : 'block' }}>
                      <WppListItem
                        onWppChangeListItem={() =>
                          showDeleteModal({
                            title: t('modals.remove_activity.delete_item'),
                            subTitle: t('modals.remove_activity.delete_confirm')!,
                            deleteText: t('common.btn_delete')!,
                            onDelete: handleDelete,
                          })
                        }
                        data-testid="context-remove"
                      >
                        <WppIconTrash slot="left" />
                        <WppTypography slot="label" type="s-body">
                          {t('common.btn_delete')}
                        </WppTypography>
                      </WppListItem>
                    </div>
                  </Flex>

                  {!isTemplate && (
                    <div style={{ display: isInactive ? 'none' : 'block' }}>
                      {/* DO_NOT_DELETE: ternary operator coz error: Can't remove ReactNode. More like Tippy issue, remove node firstly */}
                      <WppDivider className={styles.actionsGap} />
                      <TaskStatusChangeDropdown
                        onChange={status => changeStatus(status)}
                        selectedStatus={
                          projectContext?.useExternalStatuses ? task?.wrike?.externalStatusId! : task?.status
                        }
                        externalStatuses={externalStatuses}
                        useExternalStatuses={projectContext?.useExternalStatuses}
                        showConfirm={false}
                      />
                    </div>
                  )}
                </WppMenuContext>
              )}
            </Flex>
          </Flex>

          {description && (
            <Truncate
              lines={3}
              type="xs-body"
              data-testid="description"
              className={styles.greyColor800}
              title={description}
            >
              {description}
            </Truncate>
          )}

          <Flex direction="column" gap={8}>
            {(task?.status === TaskStatus.COMPLETED || task?.status === TaskStatus.ARCHIVED) && (
              <StatusText
                isExternal={projectContext?.useExternalStatuses}
                statusKey={projectContext?.useExternalStatuses ? task?.wrike?.externalStatus! : task?.status}
                className={clsx({ [styles.disabledItem]: isDisabled })}
              />
            )}
            <Flex
              gap={!showAction || isInactive ? 10 : 0}
              align="center"
              className={clsx({ [styles.disabledItem]: isDisabled })}
            >
              {!isTemplate && (
                <>
                  {showAction && !projectContext?.isInactive ? (
                    <Flex gap={4} align="center">
                      <SelectPersonInline
                        selectedId={assignMember?.id}
                        onChange={changeAssignee}
                        projectId={projectId}
                        isWrikeConnected={isWrikeConnected}
                      >
                        <ResponsiblePerson assignMember={assignMember} size="xs" />
                      </SelectPersonInline>

                      <SelectDateInline
                        startDate={startDate}
                        endDate={endDate}
                        resolved={isResolved}
                        onChange={changeDates}
                      />
                    </Flex>
                  ) : (
                    <>
                      {isUIPartEnabled(ProjectPartKey.ResponsiblePersons) && (
                        <ResponsiblePerson assignMember={assignMember} data-testid="phase-item-assignee" size="xs" />
                      )}
                      {isUIPartEnabled(ProjectPartKey.Dates) && (
                        <Calendar
                          startDate={startDate}
                          endDate={endDate}
                          data-testid="phase-item-dates"
                          resolved={isResolved}
                        />
                      )}
                    </>
                  )}
                </>
              )}

              {showAction && (
                <div className={clsx(styles.addMenuWrapper, { [styles.isDisabled]: isInactive })}>
                  <WppMenuContext data-testid="context-add">
                    <WppActionButton slot="trigger-element" disabled={projectContext?.isInactive}>
                      <WppIconPlus slot="icon-start" />
                      {t('project.canvas.btn_add_item')}
                    </WppActionButton>

                    <Flex direction="column" gap={4}>
                      <WppListItem
                        onWppChangeListItem={() =>
                          showAppPickerModal({
                            projectId: projectId,
                            selectedCanvas: processType,
                            activityId: id,
                            isTemplate,
                          })
                        }
                        data-testid="context-add-app"
                      >
                        <WppIconApp slot="left" />
                        <p slot="label">{t('project.canvas.application')}</p>
                      </WppListItem>
                      <WppListItem
                        onWppChangeListItem={() => showAttachFilesModal({ projectId, activityId: activityItem.id })}
                        data-testid="context-add-file"
                      >
                        <WppIconFile slot="left" />
                        <p slot="label">{t('project.canvas.files')}</p>
                      </WppListItem>
                      <WppListItem
                        disabled={(sortedExternalLinks?.length ?? 0) >= MAX_LINKS_COUNT}
                        onWppChangeListItem={() =>
                          showAddLinksModal({
                            projectId,
                            activityId: activityItem.id,
                            existingCount: sortedExternalLinks?.length ?? 0,
                          })
                        }
                      >
                        <WppIconLink slot="left" />
                        <p slot="label">{t('project.canvas.link')}</p>
                      </WppListItem>
                    </Flex>
                  </WppMenuContext>
                </div>
              )}
            </Flex>
          </Flex>
        </Flex>
        <WppDivider className={styles.divider} />

        {!!combinedFilesAndLinks.length && (
          <Flex direction="column" gap={4} className={styles.cardWrapper} data-testid="activity-files-and-links">
            <ShowMoreItems maxToShow={3}>
              {combinedFilesAndLinks.map(item => (
                <div key={item.id}>
                  {'fileType' in item && item.fileType && (
                    <AttachedFile
                      file={item}
                      key={item.id}
                      activityId={activityItem.id}
                      isDisabled={isDisabled}
                      showAction={showAction}
                      isInactive={projectContext?.isInactive}
                    />
                  )}

                  {'url' in item && item.url && (
                    <ExternalLinkView
                      activityId={activityItem.id}
                      link={item}
                      isDisabled={isDisabled}
                      key={item.id}
                      isInactive={projectContext?.isInactive}
                      linkActions={showAction}
                    />
                  )}
                </div>
              ))}
            </ShowMoreItems>
          </Flex>
        )}
        <Flex
          ref={drop}
          direction="column"
          gap={12}
          className={styles.droppableStyle}
          data-id="activity-droppable-container"
          data-testid="activity-droppable-container"
        >
          {activityItem.items?.map((t, i) => {
            return (
              <DragActivityItem
                activityApplicationItem={t}
                isEditable={isEditable}
                index={i}
                variant="primary"
                projectId={projectId}
                key={t.id}
                phaseId={phaseId}
                isIAssignToThisActivity={isIAssignToThisActivity}
                isIAssignToThisPhase={isIAssignToThisPhase}
                isWrikeConnected={isWrikeConnected}
                isTemplate={isTemplate}
              />
            )
          })}
          {isOverCurrent && <Placeholder className={styles.placeholder} height={placeholderHeight} />}
        </Flex>
      </WppCard>
    </>
  )
}
