import {
  WppDivider,
  WppCard,
  WppTag,
  WppTooltip,
  WppTypography,
  WppActionButton,
  WppIconEyeOff,
  WppIconEyeOn,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { ACTION_ANALYTICS } from 'constants/analytics'
import { useProject } from 'hooks/useProject'
import { useTemplate } from 'hooks/useTemplate'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import { AppLaunchWarnings } from 'pages/project/components/canvas/components/appLaunchWarnings/AppLaunchWarnings'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import { SelectDateInline } from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { SelectPersonInline } from 'pages/project/components/canvas/components/selectPersonInline/SelectPersonInline'
import styles from 'pages/project/components/canvas/fluidCanvas/components/fluidApplication/FluidApplication.module.scss'
import { ManageItemMenu } from 'pages/project/components/canvas/fluidCanvas/components/manageItemMenu/ManageItemMenu'
import { useUpdateItem } from 'pages/project/components/canvas/hooks/useUpdateItem'
import { showConfirmExternalModal } from 'pages/project/components/members/components/confirmIExternalModal/ConfirmExternalModal'
import { StatusText } from 'pages/project/components/tasks/components/statusText/StatusText'
import { Members } from 'types/members/members'
import { NativeAppVersionType } from 'types/products/nativeApp'
import { ProjectMember } from 'types/projects/projectMembers'
import { ProcessType } from 'types/projects/projects'
import { TaskStatus } from 'types/projects/tasks'
import { ApplicationItem, PhaseItemType } from 'types/projects/workflow'
import { capitalizeFirstLetter } from 'utils/common'
import { isTaskStatusResolved } from 'utils/project'

interface Props {
  application: ApplicationItem
  handleOpenApp?: () => void
  assignMember?: Members
  showAction?: boolean
  isDisabled?: boolean
  activityId?: string
}

export const FluidEditableApplication = memo(
  ({ showAction, application, handleOpenApp, assignMember, isDisabled, activityId }: Props) => {
    const { t } = useTranslation()
    const { name, startDate, endDate, externalAppName, task, externalAppVersions, externalAppVersionId } = application
    const isResolved = !!task && isTaskStatusResolved(task)

    const projectContext = useProject()
    const templateContext = useTemplate()

    const isTemplate = !!templateContext

    const projectId = projectContext?.project.id || templateContext?.template.id
    const isInactive = projectContext?.isInactive

    const { updateItem } = useUpdateItem({
      id: application.id,
      type: PhaseItemType.Application,
      name: application.name,
      projectId,
      processType: ProcessType.FLUID,
      useExternalStatuses: projectContext?.useExternalStatuses,
    })

    const toggleAssignee = useCallback(
      async (newAssignee: ResponsibleUser) => {
        const isDeselecting = assignMember?.id === newAssignee.id

        if (isDeselecting || (!newAssignee.isExternal && newAssignee.isMember)) {
          await updateItem({
            assignUser: isDeselecting ? null : newAssignee,
            analyticsAction: ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_ASSIGN,
          })
        } else {
          showConfirmExternalModal({
            title: t(
              newAssignee.isExternal
                ? 'modals.invite_member.assign_external_title'
                : 'modals.invite_member.assign_member_title',
            ),
            description: t(
              newAssignee.isExternal
                ? newAssignee.isMember
                  ? 'modals.invite_member.assign_external_description_member'
                  : 'modals.invite_member.assign_external_description'
                : 'modals.invite_member.assign_member_description',
              {
                member: `${newAssignee.firstname} ${newAssignee.lastname}`,
                item: application.name,
              },
            ),
            withDelete: false,
            isMember: newAssignee.isMember,
            members: [newAssignee as unknown as ProjectMember],
            inviteMembers: async ids => {
              if (!!ids.length) {
                await updateItem({
                  assignUser: isDeselecting ? null : newAssignee,
                  analyticsAction: ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_ASSIGN,
                })
              }
            },
          })
        }
      },
      [application.name, assignMember?.id, t, updateItem],
    )

    const changeHidden = (hidden: boolean) =>
      updateItem({ hidden, analyticsAction: ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_VISIBILITY_CHANGE })

    const selectedVersion = useMemo(
      () => externalAppVersions?.find(version => version.id === externalAppVersionId),
      [externalAppVersionId, externalAppVersions],
    )

    return (
      <WppCard
        variant={!!activityId ? 'secondary' : 'primary'}
        className={clsx(styles.itemContainer, { [styles.disabledItem]: isDisabled || isInactive })}
        data-testid={`phase-item-card-${application.id}`}
      >
        <Flex direction="column" gap={8}>
          <Flex inline direction="column" className={styles.logo} justify="center" align="center">
            <ApplicationLogo logo={application?.logoUrl} />
          </Flex>
          <Flex direction="column">
            <Truncate lines={1} title={name || externalAppName} type="m-strong" data-testid="phase-item-name">
              {name || externalAppName}
            </Truncate>
            {task?.status === TaskStatus.COMPLETED || task?.status === TaskStatus.ARCHIVED ? (
              <StatusText
                isExternal={projectContext?.useExternalStatuses}
                statusKey={projectContext?.useExternalStatuses ? task?.wrike?.externalStatus! : task?.status}
              />
            ) : (
              <WppTypography type="s-body" className={styles.statusPlaceholder} />
            )}
          </Flex>
          <WppDivider />

          {showAction && !isInactive ? (
            <Flex align="center" justify="between" className={styles.inlinePlaceholder}>
              <Flex align="center" gap={4}>
                {!isTemplate && (
                  <>
                    <SelectPersonInline
                      selectedId={assignMember?.id}
                      onChange={toggleAssignee}
                      projectId={projectId}
                      isWrikeConnected={!!projectContext?.project.wrike?.isConnected}
                    >
                      <ResponsiblePerson assignMember={assignMember} size="xs" />
                    </SelectPersonInline>

                    <SelectDateInline
                      startDate={startDate}
                      endDate={endDate}
                      resolved={isResolved}
                      onChange={dates =>
                        updateItem({ dates, analyticsAction: ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_DATES_EDIT })
                      }
                    />
                  </>
                )}

                <WppActionButton
                  onClick={() => changeHidden(!application.hidden)}
                  data-testid="application-hide-action"
                >
                  {application.hidden ? (
                    <WppIconEyeOff data-testid="application-hide-off" />
                  ) : (
                    <WppIconEyeOn data-testid="application-hide-on" />
                  )}
                </WppActionButton>
              </Flex>
              {selectedVersion && selectedVersion.versionType !== NativeAppVersionType.PRODUCTION && (
                <WppTooltip text={selectedVersion.name}>
                  <WppTag variant="neutral" label={capitalizeFirstLetter(selectedVersion.versionType.toLowerCase())} />
                </WppTooltip>
              )}
            </Flex>
          ) : (
            <Flex gap={16} align="center" justify="between" className={styles.inlinePlaceholder}>
              {!isTemplate && (
                <Flex gap={10} align="center">
                  <ResponsiblePerson assignMember={assignMember} size="xs" />
                  <Calendar startDate={startDate} endDate={endDate} resolved={isResolved} />
                </Flex>
              )}
              {selectedVersion && selectedVersion.versionType !== NativeAppVersionType.PRODUCTION && (
                <WppTooltip text={selectedVersion.name}>
                  <WppTag variant="neutral" label={capitalizeFirstLetter(selectedVersion.versionType.toLowerCase())} />
                </WppTooltip>
              )}
            </Flex>
          )}
        </Flex>
        <Flex slot="actions" align="center" style={{ position: 'relative' }} gap={4}>
          {/* @TODO: Need to fix this, create new component or change the way how we show this  */}
          {projectContext && <AppLaunchWarnings application={application} />}
          {!!showAction && !isInactive && (
            <ManageItemMenu
              application={application}
              handleOpenApp={handleOpenApp}
              updateStatus={status =>
                updateItem({ status, analyticsAction: ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_STATUS_CHANGE })
              }
              isInactive={isInactive}
              projectId={projectId}
              isWrikeConnected={!!projectContext?.project.wrike?.isConnected}
              isTemplate={isTemplate}
            />
          )}
        </Flex>
      </WppCard>
    )
  },
)
