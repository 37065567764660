export enum PAGE_ANALYTICS {
  PAGE_PROJECTS = 'page_projects',
  PAGE_PROJECT_DETAILS = 'page_project_details',
  PAGE_PROJECT_CREATE = 'page_project_create',
}

export enum ACTION_ANALYTICS {
  ACTION_PAGE_PROJECTS = 'action_page_projects',
  ACTION_PROJECT_CREATE = 'action_project_create',
  ACTION_PROJECT_WORKFLOW_VIEW = 'action_project_workflow_view',
  APPLICATION_LAUNCH = 'app_launch',
  ACTION_WORKFLOW_ITEM_ADD = 'action_workflow_item_add',
  ACTION_WORKFLOW_ITEM_MOVE = 'action_workflow_item_move',
  ACTION_WORKFLOW_ITEM_DELETE = 'action_workflow_item_delete',
  ACTION_CANVAS_REDIRECT = 'action_canvas_redirect',
  ACTION_WORKFLOW_CONNECTION_REMOVED = 'action_workflow_connection_removed',
  ACTION_WORKFLOW_CONNECTION_ADDED = 'action_workflow_connection_added',
  ACTION_WORKFLOW_ITEM_ASSIGN = 'action_workflow_item_assign',
  ACTION_WORKFLOW_ITEM_DATES_EDIT = 'action_workflow_item_dates_edit',
  ACTION_WORKFLOW_ITEM_VISIBILITY_CHANGE = 'action_workflow_item_visibility_change',
  ACTION_WORKFLOW_ITEM_STATUS_CHANGE = 'action_workflow_item_status_change',
  ACTION_WORKFLOW_ITEM_EDIT = 'action_workflow_item_edit',
  ACTION_WORKFLOW_ITEM_EDIT_CANCELLED = 'action_workflow_item_edit_cancelled',
  ACTION_VIEW_PROJECT_DETAILS = 'action_view_project_details',
  ACTION_VIEW_PROJECT_CANVAS = 'action_view_project_canvas',
  ACTION_PROJECTS_VIEW = 'action_projects_view',
  ACTION_PROJECT_CREATE_CANCELLED = 'action_project_create_cancelled',
  ACTION_VIEW_PROJECT_ABOUT = 'action_view_project_about',
  ACTION_PROJECT_EDIT = 'action_project_edit',
  ACTION_PROJECT_EDIT_CANCELLED = 'action_project_edit_cancelled',
  ACTION_PROJECT_WRIKE_CONNECTED = 'action_project_wrike_connected',
  ACTION_PROJECT_WRIKE_DISCONNECTED = 'action_project_wrike_disconnected',
  ACTION_PROJECT_HIERARCHY_CHANGED = 'action_project_hierarchy_changed',
  ACTION_PROJECT_PROCESS_TYPE_CHANGED = 'action_project_process_type_changed',
  ACTION_VIEW_TEMPLATES = 'action_view_templates',
  ACTION_TEMPLATE_EDIT = 'action_template_edit',
  ACTION_TEMPLATE_CREATE_CANCELLED = 'action_template_create_cancelled',
  ACTION_TEMPLATE_DELETE = 'action_template_delete',
  ACTION_TEMPLATE_CREATE = 'action_template_create',
  ACTION_VIEW_WORKFLOW_TEMPLATE = 'action_view_workflow_template',
}

export enum LAUNCH_LOCATIONS {
  NavigationMenu = 'Navigation Menu',
  Breadcrumbs = 'Breadcrumbs',
  ProjectWorkflow = 'Project Workflow',
  Favorites = 'Favorites',
  HamburgerMenu = 'Hamburger Menu',
}

export enum CanvasItemType {
  ACTIVITY = 'activity',
  PHASE = 'phase',
  APPLICATION = 'application',
  FILE = 'file',
  LINK = 'link',
}

export enum EventPageSource {
  CANVAS = 'canvas',
  WORKFLOW = 'workflow',
  TIMELINE = 'timeline',
}
